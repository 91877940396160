<template>
  <router-view />
</template>

<script>

export default {
  name: 'UserTemplate'
}
</script>

<style lang="scss">
</style>
